// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ILimitGroupResponse } from 'common/api/resources/LimitGroup';
import { IIconResponse } from 'common/api/resources/Icon';
import {
    DiskCacheMode,
    IShortVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { ISchedule } from 'common/api/resources/model';
import { IPlanLimit } from 'common/api/resources/Plan';
import { IShortComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { IpBlockType } from 'common/api/resources/IpBlock';

export enum ComputeResourcesBalanceStrategy {
    RANDOM = 'random',
    ROUND_ROBIN = 'round-robin',
    MOST_STORAGE_AVAILABLE = 'most-storage-available',
    MOST_MEMORY_AVAILABLE = 'most-memory-available',
}

// should be synced with events in backend/api/v1/Settings/Models/NotificationsSettings.php
export enum NotificationEvent {
    SERVER_CREATE = 'server_create',
    SERVER_RESET_PASSWORD = 'server_reset_password',
    USER_RESET_PASSWORD = 'user_reset_password',
    USER_VERIFY_EMAIL = 'user_verify_email',
    TWO_FACTOR_AUTH_RECOVERY_CODE = 'two_factor_auth_recovery_code',
    PROJECT_USER_INVITE = 'project_user_invite',
    PROJECT_USER_LEFT = 'project_user_left',
    SERVER_INCOMING_TRAFFIC_EXCEEDED = 'server_incoming_traffic_exceeded',
    SERVER_OUTGOING_TRAFFIC_EXCEEDED = 'server_outgoing_traffic_exceeded',
}

// should be synced with LicenseModel in backend/api/v1/Settings/Enums/LicenseModel.php
export enum LicenseModel {
    SOLUS_VM_V1 = 'solus_vm_v1',
    SOLUS_IO = 'solus_io',
}

export const LICENSE_MODEL_TRANSLATION_MAP = {
    [LicenseModel.SOLUS_VM_V1]: 'SolusVM',
    [LicenseModel.SOLUS_IO]: 'SolusIO',
};

export interface ISettingsMail {
    host: string;
    port: number;
    username: string;
    password: string;
    encryption: boolean;
    is_enabled: boolean;
    from_email: string;
    from_name: string;
    test_mail: string;
    send_test_mail: boolean;
}

export interface ISettingsTheme {
    primary_color: string;
    secondary_color: string;
    brand_name: string;
    logo: string | null;
    favicon: string | null;
    terms_and_conditions_url: string;
}

export interface ISettingsNetworkRules {
    arp: boolean;
    dhcp: boolean;
    cloud_init: boolean;
    smtp: boolean;
    icmp: boolean;
    icmp_reply: boolean;
    ip_spoofing: boolean;
    portmapper: boolean;
}

export interface INotificationSettings {
    enabled: boolean;
    override_templates: Record<string, boolean>;
    subject_templates: Record<string, string>;
    body_templates: Record<string, string>;
}

export interface ISettingsNotifications {
    server_create: INotificationSettings;
    server_reset_password: INotificationSettings;
    user_reset_password: INotificationSettings;
    user_verify_email: INotificationSettings;
    two_factor_auth_recovery_code: INotificationSettings;
    project_user_invite: INotificationSettings;
    project_user_left: INotificationSettings;
    server_incoming_traffic_exceeded: INotificationSettings;
    server_outgoing_traffic_exceeded: INotificationSettings;
}

export interface ISettingsComputeResource {
    balance_strategy: ComputeResourcesBalanceStrategy;
    rescue_iso_url: string;
    vs_disk_cache_mode: DiskCacheMode;
    libvirt_xml_cpu: string;
    libvirt_xml_os_mode_aarch64: string;
    libvirt_xml_os_mode_x86_64_bios: string;
    libvirt_xml_os_mode_x86_64_efi: string;
    is_storage_tier_list_enabled: boolean;
}

export interface ISettingsNonExistentVmsRemover {
    enabled: boolean;
    interval: number;
}

export enum DNSType {
    NULL = 'null',
    POWERDNS = 'powerdns',
}

export enum BillingType {
    NULL = 'null',
    WHMCS = 'whmcs',
    CUSTOM = 'custom',
}

export interface IPowerDNSSettings {
    host: string;
    api_key: string;
    port: number | null;
}

export interface IWHMCSSettings {
    url: string;
    token: string;
}

export interface ISettingsDNS {
    type: DNSType;
    server_hostname_template: string;
    reverse_dns_domain_template: string;
    register_fqdn_on_server_create: boolean;
    ttl: number;
    drivers: {
        [DNSType.POWERDNS]: IPowerDNSSettings;
    };
}

export interface ISettingsBillingIntegration {
    type: BillingType;
    drivers: {
        [BillingType.WHMCS]: IWHMCSSettings;
    };
}

export enum UPDATE_METHOD {
    AUTO = 'auto',
    MANUAL = 'manual',
}

export interface ISettingsUpdate {
    method: UPDATE_METHOD;
    channel: string;
}

export interface ISettingsUpdateSchedule {
    scheduled_days: number[];
    scheduled_time: string;
}

export interface IFeatures {
    [key: string]: boolean;
}

export enum FEATURE {
    // Values of this enum should be synced with `backend/api/v1/Settings/ValueObjects/Features.php`
    // constants.
    HIDE_PLAN_SECTION = 'hide_plan_section',
    HIDE_LOCATION_SECTION = 'hide_location_section',
    HIDE_PLAN_NAME = 'hide_plan_name',
    HIDE_USER_DATA = 'hide_user_data',
    HIDE_API_DOCUMENTATION_LINK = 'hide_api_documentation_link',

    ALLOW_REGISTRATION = 'allow_registration',
    ALLOW_PASSWORD_RECOVERY = 'allow_password_recovery',
}

export interface ISocialiteProvider {
    enabled: boolean;
    provider: string;
    icon: IIconResponse | null;
}

export interface ISettingsRegistration {
    role: string;
}

export interface ISettingsLicense {
    model: LicenseModel;
}

export interface ISettingsManagementNodeBackup {
    is_enabled: boolean;
    limit: IPlanLimit;
    schedule: ISchedule;
    backup_node_id: number | null;
}

export interface ISettingsServerBackup {
    is_enabled: boolean;
    limit: IPlanLimit;
    is_incremental_backup_enabled: boolean;
    incremental_backups_limit: number;
    schedule: ISchedule;
    compute_resources: number[];
    excluded_servers: number[];
}

export interface ISettingsServerBackupResponse extends Omit<ISettingsServerBackup, 'compute_resources' |'excluded_servers'> {
    compute_resources: IShortComputeResourceResponse[];
    excluded_servers: IShortVmResponse[];
}

export interface ISettingsAuth {
    is_session_timeout_enabled: boolean;
    session_timeout: number;
    single_session_only: boolean;
    allow_different_ips: boolean;
}

export interface ISettingsServerCreation {
    hide_ip_type_selection: boolean;
    default_selected_ip_types: IpBlockType[];
}

export interface ISettingsVpcNetwork {
    mtu: number;
}

export interface IHttpSettings {
    trusted_proxies: string[];
    true_ip_header?: string;
}

export interface ISettingsRequest {
    send_statistic?: boolean;
    hostname?: string | null;
    frontend_hostname?: string | null;
    limit_group?: number | null;
    registration?: ISettingsRegistration;
    mail?: ISettingsMail;
    compute_resource?: ISettingsComputeResource;
    non_existent_vms_remover?: ISettingsNonExistentVmsRemover;
    billing_integration?: ISettingsBillingIntegration;
    theme?: ISettingsTheme;
    network_rules?: ISettingsNetworkRules;
    notifications?: ISettingsNotifications;
    dns?: ISettingsDNS;
    update?: ISettingsUpdate;
    update_schedule?: ISettingsUpdateSchedule;
    features?: IFeatures;
    license?: ISettingsLicense;
    management_node_backup?: ISettingsManagementNodeBackup;
    server_backup?: ISettingsServerBackup;
    auth?: ISettingsAuth;
    server_creation?: ISettingsServerCreation;
    vpc_network?: ISettingsVpcNetwork;
    http?: IHttpSettings;
}

export interface ISettingsResponse {
    hostname: string;
    frontend_hostname?: string;
    send_statistic: boolean;
    limit_group: ILimitGroupResponse | null;
    registration: ISettingsRegistration;
    mail: ISettingsMail;
    compute_resource: ISettingsComputeResource;
    non_existent_vms_remover: ISettingsNonExistentVmsRemover;
    billing_integration: ISettingsBillingIntegration;
    theme: ISettingsTheme;
    network_rules: ISettingsNetworkRules;
    notifications: ISettingsNotifications;
    dns: ISettingsDNS;
    update: ISettingsUpdate;
    update_schedule: ISettingsUpdateSchedule;
    update_channels: string[];
    features: IFeatures;
    socialite_providers: ISocialiteProvider[];
    license: ISettingsLicense;
    management_node_backup: ISettingsManagementNodeBackup;
    server_backup: ISettingsServerBackupResponse;
    auth: ISettingsAuth;
    server_creation: ISettingsServerCreation;
    vpc_network: ISettingsVpcNetwork;
    http: IHttpSettings;
}
